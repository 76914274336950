
import { defineComponent } from "vue";
import PrescriberContent from "@/components/prescribers/PrescriberContent.vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";

export default defineComponent({
  name: "NewPrescriber",
  components: {
    CardTitleJaya,
    PrescriberContent,
  },
});
