import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header card-header-jaya small border-primary" }
const _hoisted_3 = { class: "card-body border-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_title_jaya = _resolveComponent("card-title-jaya")!
  const _component_PrescriberContent = _resolveComponent("PrescriberContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_card_title_jaya, {
        "duotune-icon": "general/gen035",
        small: "",
        title: "Nouveau prescripteur"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_PrescriberContent, { mode: "create" })
    ])
  ]))
}